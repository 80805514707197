import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useRecorder } from 'react-microphone-recorder';

function MicRecorder({ onAudioURLChange }) {
  const { 
    audioLevel, 
    startRecording, 
    pauseRecording, 
    stopRecording, 
    resetRecording, 
    timeElapsed, 
    audioURL, 
    isRecording 
  } = useRecorder();

  // Update parent component when audioURL changes
  useEffect(() => {
    if (audioURL && typeof onAudioURLChange === 'function') {
      onAudioURLChange(audioURL);
    }
  }, [audioURL, onAudioURLChange]);

  function toggleRecording() {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  }

  // Convert the normalized audioLevel (0 - 1) to a percentage (0 - 100)
  const amplitudePercentage = Math.min(audioLevel * 1, 100);

  return (
    <Container className="mt-4">
      {/* Custom Amplitude Bar */}
      <Row className="mb-3">
        <Col>
          <div
            style={{
              height: '20px',
              backgroundColor: '#eee',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                height: '100%',
                width: `${amplitudePercentage}%`,
                backgroundColor: '#76c7c0',
                transition: 'none',
              }}
            />
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="text-center">
          <p>Recording Time: {timeElapsed}s</p>
        </Col>
      </Row>

      {/* Recording Controls */}
      <Row className="mb-3">
        <Col className="text-left">
          <Button 
            variant={ isRecording ? "danger" : "primary" }
            onClick={toggleRecording} 
            className="mx-1"
          >
            { isRecording && "Stop Recording" }
            { !isRecording && "Start Recording" }
          </Button>
          {/* <Button 
            variant="danger" 
            onClick={stopRecording} 
            className="mx-1"
          >
            Stop
          </Button> */}
          {/* <Button 
            variant="secondary" 
            onClick={resetRecording} 
            className="mx-1"
          >
            Reset
          </Button> */}
        </Col>
      </Row>
    </Container>
  );
}

export default MicRecorder;
