import React, { useState, useEffect, useMemo, useRef } from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";

function CopyImageButton(props) {
  const [buttonLabel, setButtonLabel] = React.useState("Copy Image");

  function handleButtonClick(e) {
    setButtonLabel("Copied!");
    putImageOnClipboard();
    setTimeout(() => {
      setButtonLabel("Copy Image");
    }, 1000);
  }

  function isClipboardItemSupported() {
    return typeof ClipboardItem !== "undefined";
  }

  function putImageOnClipboard() {
    // Annoyingly, we have to convert back to PNG for copying.  It's still better to keep the images as JPG
    // because we need to fit them in browser local storage.
    const image = new Image();
    image.src = props.base64Image;
    image.onload = function() {
      // Draw the image onto a canvas
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);

      // Convert the canvas to a PNG data URL
      const pngUrl = canvas.toDataURL('image/png');

      // Convert the PNG URL to a Blob
      fetch(pngUrl)
        .then(res => res.blob())
        .then(blob => {
          // Copy the Blob to the clipboard
          navigator.clipboard.write([new ClipboardItem({'image/png': blob})])
            .then(() => console.log("PNG image copied to clipboard"))
            .catch(err => console.error("Error copying image: ", err));
        });
    };
  }

  if (!isClipboardItemSupported()) {
    return <></>;
  }

  return (
    <Button variant="outline-secondary" onClick={handleButtonClick} style={{marginTop: '5px'}}>
      {buttonLabel}
    </Button>
  );
}
export { CopyImageButton };
