import React from 'react';

const testimonials = [
    "One of the best AI workshops I've ever done. I love how simple the AI tools were. Our instructor Robbie was very kind and knowledgeable. If I had trouble with something, he made it easy for me to understand.",
  "You guys thought of it all! Thank you for being so organized, informative and kind!!",
//   "I really liked what I did here and engineering and coding are my favorite things, so this was a blast!",
  "I had an amazing time and I want to do it again.",
  "This was the best workshop I’ve done—it was very fun and informative.",
//   "I just think it’s great.",
  "Really liked it, had lots of fun, and the staff was really nice.",
//   "This was amazing and I don’t know if it could really be improved.",
  "It was a really fun workshop, tested our engineering skills hands-on.",
//   "I had a great time—thank you for the experience!",
//   "It was an amazing experience!",
//   "The most engaging and interesting part of my time at this camp so far.",
//   "I had a lot of fun learning about neural networks and AI tools.",
  "Neural network game made a complicated concept easy to understand.",
//   "I love the discussion at the end about AI now and the ethics behind it.",
//   "I was intrigued and interested by the information presented by my instructor. I had a great time with this workshop.",
//   "It was a great time to learn neural networks.",
  "I had a lot of fun! I really liked all parts. This really helped me get a better understanding of how AI works.",
//   "I enjoyed the entire workshop; however, the one thing I would include would be more on ethics and a section on AI development careers and job opportunities.",
//   "Loved it! Thank you so much for this experience."
];

const Testimonials = () => {
  return (
    <>
    <div>
        <ul>
            <li>Step 1: Please speak naturally and clearly.</li>
            <li>Step 2: Read the following text aloud in its entirety.</li>
            <li>Step 3: Once you’ve finished, select the stop and clone voice button</li>
        </ul>
    </div>
    <div className="testimonials-block">
      {testimonials.map((quote, index) => (
        <div key={index} className="testimonial-line">
          “{quote}”
        </div>
      ))}
    </div>
    </>
  );
};


export { Testimonials };
