import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import WebFont from "webfontloader";
import {
  Canvas,
  Rect,
  Textbox,
  FabricImage,
  Circle,
  Triangle,
  Line,
  ActiveSelection,
} from "fabric";
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaUndo,
  FaRedo,
  FaFont,
  FaSquare,
  FaCircle,
  FaUpload,
  FaBold,
  FaItalic,
  FaMinus,
  FaArrowUp,
  FaArrowDown,
  FaFileImage,
  FaFileCode,
  FaTrash,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
} from "react-icons/fa";
import { GiTriangleTarget } from "react-icons/gi";
import { Sketch } from "@uiw/react-color";

// -------------------------
// Font Options and Select Styles
// -------------------------
const fontOptions = [
  { value: "Roboto", label: "Roboto" },
  { value: "Pacifico", label: "Pacifico" },
  { value: "Lobster", label: "Lobster" },
  { value: "Bangers", label: "Bangers" },
  { value: "Fredoka One", label: "Fredoka One" },
  { value: "Indie Flower", label: "Indie Flower" },
  { value: "Oswald", label: "Oswald" },
  { value: "Amatic SC", label: "Amatic SC" },
  { value: "Chewy", label: "Chewy" },
  { value: "Special Elite", label: "Special Elite" },
  { value: "Dancing Script", label: "Dancing Script" },
  { value: "Great Vibes", label: "Great Vibes" },
  { value: "Merienda", label: "Merienda" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Raleway", label: "Raleway" },
  { value: "Playfair Display", label: "Playfair Display" },
  { value: "Lora", label: "Lora" },
  { value: "Abril Fatface", label: "Abril Fatface" },
  { value: "Caveat", label: "Caveat" },
  { value: "Satisfy", label: "Satisfy" },
  // ... add more if desired
];

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    fontFamily: state.data.value,
    fontSize: "0.8rem",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: state.data.value,
    fontSize: "0.8rem",
  }),
  control: (provided) => ({
    ...provided,
    minWidth: "150px",
    fontSize: "0.8rem",
  }),
};

const MAX_UNDO_STEPS = 50;

// -------------------------
// Font Size Options for the dropdown
// -------------------------
const fontSizeOptions = [
  { value: 8, label: "8" },
  { value: 10, label: "10" },
  { value: 12, label: "12" },
  { value: 14, label: "14" },
  { value: 16, label: "16" },
  { value: 18, label: "18" },
  { value: 20, label: "20" },
  { value: 24, label: "24" },
  { value: 28, label: "28" },
  { value: 32, label: "32" },
  { value: 36, label: "36" },
  { value: 48, label: "48" },
  { value: 60, label: "60" },
  { value: 72, label: "72" },
];

const PUSH_INTERVAL = 30000; // 30 seconds

// -------------------------
// Main Component
// -------------------------
const MoviePosterEditor = (props) => {
  // Load Google Fonts via WebFont Loader
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Roboto:400,700",
          "Pacifico",
          "Lobster",
          "Bangers",
          "Fredoka One",
          "Indie Flower",
          "Oswald:400,700",
          "Amatic SC:400,700",
          "Chewy",
          "Special Elite",
          "Dancing Script",
          "Great Vibes",
          "Merienda",
          "Montserrat:400,700",
          "Raleway:400,700",
          "Playfair Display:400,700",
          "Lora:400,700",
          "Abril Fatface",
          "Caveat",
          "Satisfy",
          // ... add additional fonts if desired
        ],
      },
    });
  }, []);

  // Refs for canvas and file input
  const canvasEl = useRef(null);
  const fileInputRef = useRef(null);
  // Clipboard for copy-paste functionality
  const clipboard = useRef(null);

  const lastPushTimeRef = useRef(0);
  const timerRef = useRef(null);

  const hasLoadedFromStorage = useRef(false);

  // State for showing the color pickers
  const [showStrokePicker, setShowStrokePicker] = useState(false);

  // Undo/redo stacks and restoration flag
  const undoStack = useRef([]);
  const redoStack = useRef([]);
  const isRestoring = useRef(false);

  // State for the currently selected object (if any single object is selected)
  const [selectedObj, setSelectedObj] = useState(null);
  // State for text properties (added textAlign with default "left")
  const [textProperties, setTextProperties] = useState({
    fontSize: 20,
    fill: "#000000",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    stroke: "#000000",
    strokeWidth: 0,
    textAlign: "left",
  });
  // State for shape properties
  const [shapeProperties, setShapeProperties] = useState({
    fill: "#ffffff",
    stroke: "#000000",
    strokeWidth: 1,
  });

  const pushSlideToServer = () => {
    if (hasLoadedFromStorage.current) {
      console.log("pushing slide to local");
      // Push slide to local data as JSON for restore if needed
      let json = props.canvasRef.current.toJSON();
      localStorage.setItem("slide", JSON.stringify(json));
    } else {
      console.log("not pushing slide to local");
    }

    const data = {
      password: localStorage.getItem("frontend-password"),
      student_id: localStorage.getItem("nn-node"),
      slide: props.canvasRef.current.toDataURL({ format: "png", quality: 1 }),
      workshop_id: props.workshopId,
    };

    fetch("/submit-slide", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    lastPushTimeRef.current = Date.now();
  };

  const maybeLoadFromStorage = () => {
    // Consider loading from local storage
    // We do this here because if you do it in useEffect, the canvas may not be initialized yet
    // Load the last saved slide from local storage
    const savedSlide = localStorage.getItem("slide");
    if (
      hasLoadedFromStorage.current === false &&
      savedSlide &&
      props.canvasRef.current
    ) {
      const slide = JSON.parse(savedSlide);
      if (slide && slide.objects && slide.objects.length > 0) {
        props.canvasRef.current.loadFromJSON(savedSlide).then(() => {
          props.canvasRef.current.renderAll();
        });
      }
    }
    hasLoadedFromStorage.current = true;
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      // This code runs on the next frame after the component is rendered.
      maybeLoadFromStorage();
    });
  }, []);

  // This function checks if it can push immediately or needs to wait.
  const schedulePush = () => {
    const now = Date.now();
    const timeSinceLastPush = now - lastPushTimeRef.current;

    if (timeSinceLastPush >= PUSH_INTERVAL) {
      pushSlideToServer();
    } else if (!timerRef.current) {
      const delay = PUSH_INTERVAL - timeSinceLastPush;
      timerRef.current = setTimeout(() => {
        timerRef.current = null;
        pushSlideToServer();
      }, delay);
    }
  };

  // Download canvas as PNG
  const handleDownloadPNG = () => {
    if (!props.canvasRef) return;
    const dataURL = props.canvasRef.current.toDataURL({
      format: "png",
      quality: 1,
    });
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "canvas.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Download canvas as SVG
  const handleDownloadSVG = () => {
    if (!props.canvasRef.current) return;
    const svg = props.canvasRef.current.toSVG();
    const blob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "canvas.svg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Save state function
  const saveState = () => {
    if (!props.canvasRef.current || isRestoring.current) return;
    const json = props.canvasRef.current.toJSON();
    undoStack.current.push(json);
    redoStack.current = [];

    // Limit the size of the undo stack
    if (undoStack.current.length > MAX_UNDO_STEPS) {
      undoStack.current.shift();
    }

    schedulePush();
  };

  const afterRender = () => {
    if (isRestoring.current) {
      isRestoring.current = false;
    }
  };

  useEffect(() => {
    if (!canvasEl.current) return;
    // Create canvas with preserveObjectStacking so that selection doesn't automatically change z-order.
    const canvas = new Canvas(canvasEl.current, {
      preserveObjectStacking: true,
    });
    props.canvasRef.current = canvas;
    // props.canvasRef.current.setDimensions({width: '200px', height: '200px'}, {cssOnly: true});

    saveState();

    const handleSelection = (e) => {
      const activeObjects = canvas.getActiveObjects();
      // When a single object is selected, update the toolbar state.
      // For multiple objects, you may choose to show mixed values.
      if (activeObjects.length === 1) {
        const selected = activeObjects[0];
        setSelectedObj(selected);
        if (selected.type === "textbox") {
          setTextProperties({
            fontSize: selected.fontSize || 20,
            fill: selected.fill || "#000000",
            fontFamily: selected.fontFamily || "Roboto",
            fontStyle: selected.fontStyle || "normal",
            fontWeight: selected.fontWeight || "normal",
            stroke: selected.stroke || "#000000",
            strokeWidth: selected.strokeWidth || 0,
            textAlign: selected.textAlign || "left",
          });
        } else {
          setShapeProperties({
            fill: selected.fill || "#ffffff",
            stroke: selected.stroke || "#000000",
            strokeWidth: selected.strokeWidth || 1,
          });
        }
      } else {
        // For multiple selection, clear the single-object state.
        setSelectedObj(null);
      }
    };

    canvas.on("selection:created", handleSelection);
    canvas.on("selection:updated", handleSelection);
    canvas.on("selection:cleared", () => setSelectedObj(null));

    canvas.on("object:modified", saveState);
    canvas.on("object:added", saveState);
    canvas.on("object:removed", saveState);
    canvas.on("after:render", afterRender);

    return () => {
      canvas.dispose();
    };
  }, []);

  // When changing text properties (like font, bold, italic, alignment), update all text objects in the selection
  const handleChange = (prop, value) => {
    setTextProperties((prev) => ({ ...prev, [prop]: value }));
    const canvas = props.canvasRef.current;
    const activeObjects = canvas.getActiveObjects();
    activeObjects.forEach((obj) => {
      if (obj.type === "textbox") {
        obj.set(prop, value);
      }
    });
    canvas.renderAll();
  };

  // When changing shape properties, update all non-text objects in the selection
  const handleShapeChange = (prop, value) => {
    setShapeProperties((prev) => ({ ...prev, [prop]: value }));
    const canvas = props.canvasRef.current;
    const activeObjects = canvas.getActiveObjects();
    activeObjects.forEach((obj) => {
      if (obj.type !== "textbox") {
        obj.set(prop, value);
      }
    });
    canvas.renderAll();
  };

  // Undo/Redo handlers
  const handleUndo = useCallback(() => {
    if (undoStack.current.length === 0 || isRestoring.current) return;
    const currentState = props.canvasRef.current.toJSON();
    redoStack.current.push(currentState);
    const prevState = undoStack.current.pop();

    if (prevState === undefined) return;

    console.log(currentState);

    isRestoring.current = true;
    props.canvasRef.current.loadFromJSON(prevState).then(() => {
      props.canvasRef.current.renderAll();
      isRestoring.current = false;
    });
  }, []);

  const handleRedo = useCallback(() => {
    if (redoStack.current.length === 0) return;
    const currentState = props.canvasRef.current.toJSON();
    undoStack.current.push(currentState);
    const nextState = redoStack.current.pop();
    isRestoring.current = true;
    props.canvasRef.current.loadFromJSON(nextState).then(() => {
      props.canvasRef.current.renderAll();
      isRestoring.current = false;
    });
  }, []);

  // Copy handler now supports multiple selection.
  const handleCopy = () => {
    const canvas = props.canvasRef.current;
    const activeObjects = canvas.getActiveObjects();
    if (activeObjects.length === 0) return;
    if (activeObjects.length === 1) {
      activeObjects[0].clone().then((clonedObj) => {
        clipboard.current = clonedObj;
      });
    } else {
      // Create an ActiveSelection from the selected objects and clone that.
      const selection = new ActiveSelection(activeObjects, { canvas });
      selection.clone().then((clonedObj) => {
        clipboard.current = clonedObj;
      });
    }
  };

  // Paste handler remains largely the same.
  const handlePaste = () => {
    if (!clipboard.current) return;
    clipboard.current.clone().then((clonedObj) => {
      const canvas = props.canvasRef.current;
      canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
      });
      if (clonedObj instanceof ActiveSelection) {
        clonedObj.canvas = canvas;
        clonedObj.forEachObject((obj) => {
          canvas.add(obj);
        });
        clonedObj.setCoords();
      } else {
        canvas.add(clonedObj);
      }
      // Update clipboard position for subsequent pastes.
      clipboard.current.left += 10;
      clipboard.current.top += 10;
      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();
    });
  };

  // Element creation handlers (these create new objects so they don’t need multi-selection logic)
  const handleAddText = () => {
    if (props.canvasRef.current) {
      const newTextbox = new Textbox("New Text", {
        fontSize: textProperties.fontSize,
        fill: textProperties.fill,
        fontFamily: textProperties.fontFamily,
        fontStyle: textProperties.fontStyle,
        fontWeight: textProperties.fontWeight,
        stroke: textProperties.stroke,
        strokeWidth: 0,
        textAlign: textProperties.textAlign,
        left: 50,
        top: 50,
        width: 200,
      });
      props.canvasRef.current.add(newTextbox);
      props.canvasRef.current.setActiveObject(newTextbox);
      props.canvasRef.current.renderAll();
    }
  };

  const handleAddRect = () => {
    if (props.canvasRef.current) {
      const newRect = new Rect({
        width: 100,
        height: 60,
        fill: shapeProperties.fill,
        stroke: shapeProperties.stroke,
        strokeWidth: shapeProperties.strokeWidth,
        left: 50,
        top: 50,
      });
      props.canvasRef.current.add(newRect);
      props.canvasRef.current.setActiveObject(newRect);
      props.canvasRef.current.renderAll();
    }
  };

  const handleAddCircle = () => {
    if (props.canvasRef.current) {
      const newCircle = new Circle({
        radius: 30,
        fill: shapeProperties.fill,
        stroke: shapeProperties.stroke,
        strokeWidth: shapeProperties.strokeWidth,
        left: 50,
        top: 50,
      });
      props.canvasRef.current.add(newCircle);
      props.canvasRef.current.setActiveObject(newCircle);
      props.canvasRef.current.renderAll();
    }
  };

  const handleAddTriangle = () => {
    if (props.canvasRef.current) {
      const newTriangle = new Triangle({
        width: 80,
        height: 80,
        fill: shapeProperties.fill,
        stroke: shapeProperties.stroke,
        strokeWidth: shapeProperties.strokeWidth,
        left: 50,
        top: 50,
      });
      props.canvasRef.current.add(newTriangle);
      props.canvasRef.current.setActiveObject(newTriangle);
      props.canvasRef.current.renderAll();
    }
  };

  const handleAddLine = () => {
    if (props.canvasRef.current) {
      const newLine = new Line([50, 50, 150, 150], {
        stroke: shapeProperties.stroke,
        strokeWidth: shapeProperties.strokeWidth,
        x1: 50,
        y1: 50,
        x2: 150,
        y2: 150,
      });
      props.canvasRef.current.add(newLine);
      props.canvasRef.current.setActiveObject(newLine);
      props.canvasRef.current.renderAll();
    }
  };

  // Layer control handlers now update every active object.
  const handleLayerUp = () => {
    const canvas = props.canvasRef.current;

    const activeObjects = canvas.getActiveObjects();

    // Need to sort objects by their current stacking order first.
    const sortedActiveObjects = activeObjects.sort((a, b) => {
      return canvas.getObjects().indexOf(b) - canvas.getObjects().indexOf(a);
    });

    sortedActiveObjects.forEach((obj) => {
      canvas.bringObjectForward(obj);
    });
    canvas.renderAll();
  };

  const handleLayerDown = () => {
    const canvas = props.canvasRef.current;
    const activeObjects = canvas.getActiveObjects();

    // Need to sort objects by their current stacking order first.
    const sortedActiveObjects = activeObjects.sort((a, b) => {
      return canvas.getObjects().indexOf(a) - canvas.getObjects().indexOf(b);
    });

    sortedActiveObjects.forEach((obj) => {
      canvas.sendObjectBackwards(obj);
    });
    canvas.renderAll();
  };

  // Delete handler now removes all active objects.
  const handleDelete = () => {
    if (!props.canvasRef.current) return;
    const canvas = props.canvasRef.current;
    const activeObjects = canvas.getActiveObjects();
    activeObjects.forEach((obj) => {
      canvas.remove(obj);
    });
    canvas.discardActiveObject();
    canvas.renderAll();
    setSelectedObj(null);
  };

  // Fixed image upload handler using promise-based FabricImage.fromURL
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (f) => {
      const data = f.target.result;
      FabricImage.fromURL(data)
        .then((img) => {
          img.set({ left: 100, top: 100 });
          props.canvasRef.current.add(img);
          props.canvasRef.current.setActiveObject(img);
          props.canvasRef.current.renderAll();
          fileInputRef.current.value = "";
        })
        .catch((error) => {
          console.error("Error loading uploaded image:", error);
        });
    };
    reader.readAsDataURL(file);
  };

  const handleUploadImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const isText = selectedObj && selectedObj.type === "textbox";
  const currentFillColor = isText ? textProperties.fill : shapeProperties.fill;
  const currentStrokeColor = isText
    ? textProperties.stroke
    : shapeProperties.stroke;

  // Global keyboard shortcuts including arrow key movement.
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (props.activeTab != "poster") {
        return;
      }
      const activeTag = document.activeElement.tagName;
      if (activeTag === "INPUT" || activeTag === "TEXTAREA") return;

      if (e.ctrlKey && !e.altKey) {
        const key = e.key.toLowerCase();
        if (key === "z") {
          e.shiftKey ? handleRedo() : handleUndo();
          e.preventDefault();
        } else if (key === "y") {
          handleRedo();
          e.preventDefault();
        } else if (key === "b") {
          if (isText) {
            handleChange(
              "fontWeight",
              textProperties.fontWeight === "bold" ? "normal" : "bold"
            );
          }
          e.preventDefault();
        } else if (key === "i") {
          if (isText) {
            handleChange(
              "fontStyle",
              textProperties.fontStyle === "italic" ? "normal" : "italic"
            );
          }
          e.preventDefault();
        } else if (key === "c") {
          handleCopy();
          e.preventDefault();
        } else if (key === "v") {
          handlePaste();
          e.preventDefault();
        }
      }
      if (e.ctrlKey && e.altKey) {
        if (e.key === "ArrowUp") {
          handleLayerUp();
          e.preventDefault();
        } else if (e.key === "ArrowDown") {
          handleLayerDown();
          e.preventDefault();
        }
      }
      if (!e.ctrlKey && !e.altKey && (e.key === "Delete" || e.keyCode === 46 || e.key === "Backspace" || e.keyCode === 8)) {
        handleDelete();
        e.preventDefault();
      }

      const obj = props.canvasRef.current.getActiveObject();
      if (
        !e.ctrlKey &&
        !e.altKey &&
        ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)
      ) {
        if (obj) {
          const step = e.shiftKey ? 20 : 5;
          let { left, top } = obj;
          if (e.key === "ArrowUp") top -= step;
          else if (e.key === "ArrowDown") top += step;
          else if (e.key === "ArrowLeft") left -= step;
          else if (e.key === "ArrowRight") left += step;
          obj.set({ left, top });
          obj.setCoords();
          props.canvasRef.current.renderAll();
          e.preventDefault();
        }
      }
      if (
        !e.ctrlKey &&
        e.altKey &&
        ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)
      ) {
        if (obj) {
          const fineStep = 1;
          let { left, top } = obj;
          if (e.key === "ArrowUp") top -= fineStep;
          else if (e.key === "ArrowDown") top += fineStep;
          else if (e.key === "ArrowLeft") left -= fineStep;
          else if (e.key === "ArrowRight") left += fineStep;
          obj.set({ left, top });
          obj.setCoords();
          props.canvasRef.current.renderAll();
          e.preventDefault();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isText, textProperties, handleRedo, handleUndo]);

  // onKeyDown for the font select (arrow keys to change font immediately)
  const handleFontKeyDown = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      let currentIndex = fontOptions.findIndex(
        (option) => option.value === textProperties.fontFamily
      );
      if (currentIndex === -1) currentIndex = 0;
      currentIndex =
        e.key === "ArrowDown"
          ? (currentIndex + 1) % fontOptions.length
          : (currentIndex - 1 + fontOptions.length) % fontOptions.length;
      const newFont = fontOptions[currentIndex].value;
      handleChange("fontFamily", newFont);
      e.preventDefault();
    }
  };

  let everythingVis = "visible";
  let goToTab = <></>;

  if (!props.readyForPoster) {
    everythingVis = "hidden";
    goToTab = (
      <h4 className="text-center mt-5">
        Go to the DALL·E tab to start creating your movie poster
      </h4>
    );
  }

  return (
    <>
      {goToTab}
      <div style={{ visibility: everythingVis }}>
        {/* Top Toolbar */}
        <Container fluid className="p-1 bg-light">
          <Row className="align-items-center">
            <Col xs="auto" className="d-flex align-items-center flex-wrap">
              <ButtonGroup className="me-2">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Undo (Ctrl+Z)</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleUndo}
                  >
                    <FaUndo size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Redo (Ctrl+Y or Ctrl+Shift+Z)</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleRedo}
                  >
                    <FaRedo size={20} />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              <ButtonGroup className="me-2">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Add Text</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleAddText}
                  >
                    <FaFont size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Add Rectangle</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleAddRect}
                  >
                    <FaSquare size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Add Circle</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleAddCircle}
                  >
                    <FaCircle size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Add Triangle</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleAddTriangle}
                  >
                    <GiTriangleTarget size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Add Line</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleAddLine}
                  >
                    <FaMinus size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Upload Image</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleUploadImageClick}
                  >
                    <FaUpload size={20} />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              <ButtonGroup className="me-2">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Layer Up (Ctrl+Alt+↑)</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleLayerUp}
                  >
                    <FaArrowUp size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Layer Down (Ctrl+Alt+↓)</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleLayerDown}
                  >
                    <FaArrowDown size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Delete object (Backspace)</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleDelete}
                  >
                    <FaTrash size={20} />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              <ButtonGroup className="me-2">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Download PNG</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleDownloadPNG}
                  >
                    <FaFileImage size={20} />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Download SVG</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleDownloadSVG}
                  >
                    <FaFileCode size={20} />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="auto" className="d-flex align-items-center flex-wrap">
            Fill: 
              <Dropdown style={{ margin: 0, padding: 0 }}>
                <Dropdown.Toggle variant="secondary-outline">
                  <span
                    style={{
                      display: "inline-block",
                      width: 32,
                      height: 32,
                      padding: 0,
                      margin: 0,
                      backgroundColor: currentFillColor,
                      border: "1px solid #ccc",
                      borderRadius: 4,
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  > 
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  renderOnMount={true}
                  style={{ textAlign: "center" }}
                >
                  <Dropdown.ItemText>
                    <Sketch
                      color={currentFillColor}
                      disableAlpha={true}
                      onChange={(color) => {
                        if (isText) handleChange("fill", color.hex);
                        else handleShapeChange("fill", color.hex);
                      }}
                    />
                  </Dropdown.ItemText>
                </Dropdown.Menu>
              </Dropdown>

              Outline:
              <Dropdown style={{ margin: 0, padding: 0 }}>
                <Dropdown.Toggle variant="secondary-outline" >
                  <span
                    style={{
                      display: "inline-block",
                      width: 32,
                      height: 32,
                      padding: 0,
                      margin: 0,
                      backgroundColor: currentStrokeColor,
                      border: "1px solid #ccc",
                      borderRadius: 4,
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  >
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  renderOnMount={true}
                  style={{ textAlign: "center" }}
                >
                  <Dropdown.ItemText>
                    <Sketch
                      color={currentStrokeColor}
                      disableAlpha={true}
                      onChange={(color) => {
                        if (isText) handleChange("stroke", color.hex);
                        else handleShapeChange("stroke", color.hex);
                      }}
                    />
                  </Dropdown.ItemText>
                </Dropdown.Menu>
              </Dropdown>

              {/* Stroke width input */}
              Outline width:
              <Form.Control
                type="number"
                size="sm"
                style={{ width: "50px", marginLeft: "5px" }}
                disabled={
                  !props.canvasRef.current ||
                  !props.canvasRef.current.getActiveObjects().length
                }
                value={
                  isText
                    ? textProperties.strokeWidth
                    : shapeProperties.strokeWidth
                }
                onChange={(e) => {
                  const newVal = parseInt(e.target.value, 10);
                  if (isText) handleChange("strokeWidth", newVal);
                  else handleShapeChange("strokeWidth", newVal);
                }}
                className="me-1"
              />

              {isText && (
                <>
                  <div style={{ width: "150px" }} className="me-1">
                    <Select
                      value={fontOptions.find(
                        (option) => option.value === textProperties.fontFamily
                      )}
                      onChange={(option) =>
                        handleChange("fontFamily", option.value)
                      }
                      options={fontOptions}
                      styles={customSelectStyles}
                      isSearchable={false}
                      onKeyDown={handleFontKeyDown}
                    />
                  </div>
                  <div style={{ width: "80px" }} className="me-1">
                    <CreatableSelect
                      value={{
                        value: textProperties.fontSize,
                        label: textProperties.fontSize.toString(),
                      }}
                      options={fontSizeOptions}
                      onChange={(option) => {
                        const newFontSize = parseInt(option.value, 10);
                        handleChange("fontSize", newFontSize);
                      }}
                      isClearable={false}
                      formatCreateLabel={(inputValue) => `${inputValue}`}
                      placeholder=""
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: "80px",
                        }),
                        control: (provided) => ({
                          ...provided,
                          minHeight: "30px",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0 6px",
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: "30px",
                        }),
                      }}
                    />
                  </div>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Bold (Ctrl+B)</Tooltip>}
                  >
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="me-1"
                      onClick={() =>
                        handleChange(
                          "fontWeight",
                          textProperties.fontWeight === "bold"
                            ? "normal"
                            : "bold"
                        )
                      }
                    >
                      <FaBold
                        size={16}
                        color={
                          textProperties.fontWeight === "bold"
                            ? "black"
                            : "gray"
                        }
                      />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Italic (Ctrl+I)</Tooltip>}
                  >
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="me-1"
                      onClick={() =>
                        handleChange(
                          "fontStyle",
                          textProperties.fontStyle === "italic"
                            ? "normal"
                            : "italic"
                        )
                      }
                    >
                      <FaItalic
                        size={16}
                        color={
                          textProperties.fontStyle === "italic"
                            ? "black"
                            : "gray"
                        }
                      />
                    </Button>
                  </OverlayTrigger>
                  {/* Alignment Buttons */}
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Align Left</Tooltip>}
                  >
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="me-1"
                      onClick={() => handleChange("textAlign", "left")}
                    >
                      <FaAlignLeft size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Align Center</Tooltip>}
                  >
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="me-1"
                      onClick={() => handleChange("textAlign", "center")}
                    >
                      <FaAlignCenter size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Align Right</Tooltip>}
                  >
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="me-1"
                      onClick={() => handleChange("textAlign", "right")}
                    >
                      <FaAlignRight size={16} />
                    </Button>
                  </OverlayTrigger>
                </>
              )}
            </Col>
          </Row>
        </Container>

        <div className="p-2" style={{ marginBottom: "20px" }}>
          <canvas
            ref={canvasEl}
            width="512"
            height="512"
            style={{ border: "1px solid black", marginTop: "10px" }}
          />
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleImageUpload}
        />
      </div>
    </>
  );
};

export { MoviePosterEditor };
