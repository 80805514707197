import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { FaArrowLeft } from "react-icons/fa";
import Button from "react-bootstrap/Button";

const Slideshow = (props) => {
  const [images, setImages] = useState([]);
  const [allWorkshopIds, setAllWorkshopIds] = useState([]);
  const [workshopId, setWorkshopId] = useState("");
  const [submittedWorkshopId, setSubmittedWorkshopId] = useState(null);
  const [loadingSlides, setLoadingSlides] = useState(false);

  // Fetch list of workshop IDs on mount.
  useEffect(() => {
    document.title = "Slideshow | Artificial Intelligence Workshop | Stage One Education";
    fetch("/list-workshops", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: localStorage.getItem("frontend-password"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the list of workshop IDs.
        setAllWorkshopIds(data.workshops);
      })
      .catch((error) => {
        console.error("Error fetching workshops:", error);
      });
  }, []);

  // Fetch slides whenever a workshop is submitted.
  useEffect(() => {
    if (!submittedWorkshopId) return;

    setLoadingSlides(true);
    const requestData = {
      password: localStorage.getItem("frontend-password"),
      workshop_id: submittedWorkshopId,
    };

    fetch("/get-slides", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Assume the response contains a `slides` array.
        const slides = data.slides || [];
        const imageItems = slides.map((slide) => ({ original: slide }));
        setImages(imageItems);
        setLoadingSlides(false);
      })
      .catch((error) => {
        console.error("Error fetching slides:", error);
        setLoadingSlides(false);
      });
  }, [submittedWorkshopId]);

  // Handler for workshop selection change.
  const handleWorkshopChange = (e) => {
    setWorkshopId(e.target.value);
  };

  // Handler for submitting the workshop selection.
  const handleSubmit = () => {
    if (workshopId !== "") {
      setSubmittedWorkshopId(workshopId);
    }
  };

  // While the list of workshops is loading, show a full-page spinner.
  if (allWorkshopIds.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading workshops...</span>
        </div>
      </div>
    );
  }

  let configSection = <></>;

  if (submittedWorkshopId === null) {
    configSection = (
      <div className="container my-4">
        {/* Config Section */}
        <div className="card shadow-sm mb-4">
          <div className="card-body">
            <h4 className="card-title mb-3">Slideshow Configuration</h4>
            <div className="mb-3">
              <label htmlFor="workshopSelect" className="form-label">
                Select Workshop:
              </label>
              <select
                id="workshopSelect"
                className="form-select"
                value={workshopId}
                onChange={handleWorkshopChange}
              >
                <option value="">-- Select a workshop --</option>
                {allWorkshopIds.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={workshopId === ""}
            >
              Go
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {configSection}
      <div className="container my-4">
        {/* Slide Show Section */}
        {submittedWorkshopId &&
          (loadingSlides ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading slides...</span>
              </div>
            </div>
          ) : (
            <div>
              <Button
                variant="secondary"
                onClick={() => setSubmittedWorkshopId(null)}
              >
                <FaArrowLeft />
              </Button>
              <ImageGallery
                items={images}
                showPlayButton={false}
                showNav={true}
                showThumbnails={false}
                infinite={false}
                swipingTransitionDuration={1000}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export { Slideshow };
