import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { ElevenLabsClient } from 'elevenlabs';
import MicRecorder from './MicRecorder';
import { Testimonials } from './Testimonials';

const VoiceCloneCreatorOptions = (props) => {
  // -----------------------
  // Common API and client setup
  // -----------------------
  // Supply your API key securely (e.g., via an environment variable)
  const apiKey = props.elevenlabsKeys[0]
  const client = useMemo(() => new ElevenLabsClient({ apiKey }), [apiKey]);
  const hasLoadedFromLocalStorage = useRef(false);

  // -----------------------
  // Shared voices state for synthesis dropdown. If you want more default voices, put them in here.
  // -----------------------
  const [voices, setVoices] = useState([
    //{ id: 'CwhRBWXzGAHq8TQ4Fs17', name: 'Roger' },
  ]);
  const [selectedVoiceId, setSelectedVoiceId] = useState('');

  // -----------------------
  // Section 1: Voice Clone Creation states
  // -----------------------
  const [voiceName, setVoiceName] = useState('My Voice Clone');
  const [selectedSource, setSelectedSource] = useState('record'); // 'record' or 'upload'
  const [uploadedFile, setUploadedFile] = useState(null);
  const [cloneResult, setCloneResult] = useState('');
  const [cloneLoading, setCloneLoading] = useState(false);
  const [audioURL, setAudioURL] = useState(null);

  // -----------------------
  // Section 2: Synthesis states
  // -----------------------
  // For text-to-speech and speech-to-speech synthesis
  const [inputText, setInputText] = useState('');
  const [audioUrlSynth, setAudioUrlSynth] = useState([]);
  const [synthLoading, setSynthLoading] = useState(false);
  const [error, setError] = useState('');

  // NEW: Synthesis input method state: "text", "record", or "file"
  const [synthInputSource, setSynthInputSource] = useState('text');
  const [synthRecordingURL, setSynthRecordingURL] = useState(null); // from microphone
  const [synthUploadedFile, setSynthUploadedFile] = useState(null);   // from local file
  const [synthesisText, setSynthesisText] = useState('');

  // Set default selected voice for synthesis if none is selected.
  useEffect(() => {
    if (voices.length > 0 && !selectedVoiceId) {
      setSelectedVoiceId(voices[0].id);
    }
  }, [voices, selectedVoiceId]);

  useEffect(() => {
    // Save the state of voices to local storage
    if (hasLoadedFromLocalStorage.current) {
      localStorage.setItem('voices', JSON.stringify(voices));
    }
  }, [voices]);

  useEffect(() => {
    // Load voices from local storage
    const storedVoices = localStorage.getItem('voices');
    if (storedVoices) {
      setVoices(JSON.parse(storedVoices));
    }
  }, []);

  function getVoiceNameFromId(id) {
    const voice = voices.find((v) => v.id === id);
    return voice ? voice.name : '';
  }

  // -----------------------
  // Handlers for Clone Creation
  // -----------------------
  const handleFileChange = (e) => {
    setUploadedFile(e.target.files[0]);
  };

  const handleSourceChange = (e) => {
    setSelectedSource(e.target.value);
    // Reset any previous audio sample.
    setAudioURL(null);
    setUploadedFile(null);
  };

  const handleCloneSubmit = async (e) => {
    e.preventDefault();
    if (!voiceName) {
      alert('Please enter a voice name.');
      return;
    }
    let fileForUpload = null;
    if (selectedSource === 'record') {
      if (!audioURL) {
        alert('Please record your voice first.');
        return;
      }
      try {
        const res = await fetch(audioURL);
        const blob = await res.blob();
        fileForUpload = new File([blob], 'voice_sample.webm', { type: blob.type });
      } catch (error) {
        alert('Error processing recorded audio.');
        console.error('Error fetching blob from audioURL:', error);
        return;
      }
    } else {
      if (!uploadedFile) {
        alert('Please upload an audio file.');
        return;
      }
      fileForUpload = uploadedFile;
    }
    setCloneLoading(true);
    setCloneResult('');
    try {
      const response = await client.voices.add({
        files: [fileForUpload],
        name: voiceName,
      });
      // Create a new voice entry using the returned voice_id and provided voiceName.
      const newVoiceEntry = { id: response.voice_id, name: voiceName };
      console.log("New voice entry:", newVoiceEntry);
      // Update the shared voices state so that the new voice appears in the synthesis dropdown.
      setVoices(prevVoices => [...prevVoices, newVoiceEntry]);
      setCloneResult(`Voice clone created successfully: ${JSON.stringify(response, null, 2)}`);
      setVoiceName('');
      setSelectedVoiceId(response.voice_id);
    } catch (error) {
      setCloneResult(`Error creating voice clone: ${error.message}`);
    } finally {
      setCloneLoading(false);
    }
  };

  // -----------------------
  // Handlers for Synthesis
  // -----------------------
  const handleVoiceDropdownChange = (e) => {
    setSelectedVoiceId(e.target.value);
  };

  const handleSynthesisSourceChange = (e) => {
    setSynthInputSource(e.target.value);
    // Reset any previous synthesis sample.
    setSynthesisText('');
    setSynthRecordingURL(null);
    setSynthUploadedFile(null);
  };

  const handleSynthFileChange = (e) => {
    setSynthUploadedFile(e.target.files[0]);
  };

  const handleSynthesize = async () => {
    if (!selectedVoiceId) {
      alert('Please select a voice.');
      return;
    }
    setSynthLoading(true);
    try {
      let audioResponse;
      if (synthInputSource === 'text') {
        if (!synthesisText) {
          alert('Please enter text to synthesize.');
          setSynthLoading(false);
          return;
        }
        const stream = await client.textToSpeech.convertAsStream(selectedVoiceId, {
          text: synthesisText,
          model_id: 'eleven_multilingual_v2',
        });
        const chunks = [];
        for await (const chunk of stream) {
          chunks.push(chunk);
        }
        audioResponse = new Blob(chunks, { type: 'audio/mpeg' });
      } else if (synthInputSource === 'record') {
        if (!synthRecordingURL) {
          alert('Please record your synthesis audio first.');
          setSynthLoading(false);
          return;
        }

        // For speech-to-speech conversion using recorded input. 
        // This was tricky as the webm format failed without much feedback
        // also, the conversionStream comes back in chunks
        const res = await fetch(synthRecordingURL);
        const blob = await res.blob();
        const fileForSynthesis = new File([blob], 'synth_input.mp3', { type: 'audio/mpeg' });
        const conversionStream = await client.speechToSpeech.convert(selectedVoiceId, {
          audio: fileForSynthesis,
          output_format: "mp3_44100_128",
          model_id: "eleven_multilingual_sts_v2"
        });
        const conversionChunks = [];
        for await (const chunk of conversionStream) {
          conversionChunks.push(chunk);
        }
        audioResponse = new Blob(conversionChunks, { type: 'audio/mpeg' });
      } else if (synthInputSource === 'file') {
        if (!synthUploadedFile) {
          alert('Please select a local MP3 file for synthesis.');
          setSynthLoading(false);
          return;
        }
        const conversionStream = await client.speechToSpeech.convert(selectedVoiceId, {
          audio: synthUploadedFile,
          output_format: "mp3_44100_128",
          model_id: "eleven_multilingual_sts_v2"
        });
        const conversionChunks = [];
        for await (const chunk of conversionStream) {
          conversionChunks.push(chunk);
        }
        audioResponse = new Blob(conversionChunks, { type: 'audio/mpeg' });
      }
      const url = URL.createObjectURL(audioResponse);

      // Add url to array of synth urls
      setAudioUrlSynth([...audioUrlSynth, {'id': selectedVoiceId, 'name': getVoiceNameFromId(selectedVoiceId), 'url': url}]);
    } catch (err) {
      setError(err.message);
      console.error('Error synthesizing speech:', err);
    } finally {
      setSynthLoading(false);
    }
  };

  let cloneVoiceButton = <></>

  if (audioURL || uploadedFile) {
    cloneVoiceButton = (
      <>
        <br />
        <Button variant="success" type="submit" className="mt-4" disabled={cloneLoading} style={{ height: '50px', width: '200px' }}>
          {cloneLoading ? 'Cloning...' : 'Clone Voice'}
        </Button>
      </>
    )
  }

  let audioSynthOut = []

  if (audioUrlSynth) {
    audioSynthOut = audioUrlSynth.map((data, index) => {
      return (
        <div key={index} className="mt-3">
          <Form.Label>{data.name}</Form.Label>
          <audio controls src={data.url} className="w-100" />
        </div>
      )
    })
  }

  // -----------------------
  // Render Combined Component
  // -----------------------
  return (
    <Container className="my-4">
      <Row>
        {/* Voice Clone Creation Section */}
        <Col md={6}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="bg-primary text-white">
              <h4>Create a Voice Clone</h4>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleCloneSubmit}>
                <Form.Group controlId="voiceName">
                  <Form.Label>Voice Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter voice name"
                    value={voiceName}
                    onChange={(e) => setVoiceName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="source" className="mt-3">
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      label="Record from Microphone"
                      id="source-record"
                      value="record"
                      checked={selectedSource === 'record'}
                      onChange={handleSourceChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Upload MP3 File"
                      id="source-upload"
                      value="upload"
                      checked={selectedSource === 'upload'}
                      onChange={handleSourceChange}
                    />
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    {selectedSource === "record" && <Testimonials />}
                  </div>
                </Form.Group>
                {selectedSource === 'record' ? (
                  <>
                    <MicRecorder onAudioURLChange={setAudioURL} />
                    {audioURL && <audio controls src={audioURL} />}
                  </>
                ) : (
                  <Form.Group controlId="fileUpload" className="mt-3">
                    <Form.Label>Upload MP3 File</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".mp3,audio/mpeg"
                      onChange={handleFileChange}
                      required
                    />
                    {uploadedFile && (
                      <Form.Text className="text-muted">{uploadedFile.name}</Form.Text>
                    )}
                  </Form.Group>
                )}
                {cloneVoiceButton}
              </Form>
              {cloneResult && (
                <Alert variant={cloneResult.startsWith('Error') ? 'danger' : 'success'} className="mt-3">
                  <pre className="mb-0">{cloneResult}</pre>
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Synthesis Section */}
        <Col md={6}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="bg-success text-white">
              <h4>Text / Speech-to-Speech Synthesis</h4>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="voiceSelect">
                  <Form.Label>Select a Voice</Form.Label>
                  <Form.Control as="select" value={selectedVoiceId} onChange={handleVoiceDropdownChange}>
                    {voices.map((voice) => (
                      <option key={voice.id} value={voice.id}>
                        {voice.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="synthSource" className="mt-3">
                  <Form.Label>Synthesis Input Source</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      label="Text Input"
                      name="synthSource"
                      value="text"
                      checked={synthInputSource === 'text'}
                      onChange={handleSynthesisSourceChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Record Input"
                      id="record-input"
                      value="record"
                      checked={synthInputSource === 'record'}
                      onChange={handleSynthesisSourceChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Upload MP3 File"
                      id="upload-mp3-input"
                      value="file"
                      checked={synthInputSource === 'file'}
                      onChange={handleSynthesisSourceChange}
                    />
                  </div>
                </Form.Group>
                {synthInputSource === 'text' && (
                  <Form.Group controlId="textInput" className="mt-3">
                    <Form.Label>Enter Text</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Enter text to synthesize"
                      value={synthesisText}
                      onChange={(e) => setSynthesisText(e.target.value)}
                    />
                  </Form.Group>
                )}
                {synthInputSource === 'record' && (
                  <Form.Group controlId="synthMicInput" className="mt-3">
                    <Form.Label>Record Input</Form.Label>
                    <MicRecorder onAudioURLChange={setSynthRecordingURL} />
                    {synthRecordingURL && <audio controls src={synthRecordingURL} />}
                  </Form.Group>
                )}
                {synthInputSource === 'file' && (
                  <Form.Group controlId="synthFileInput" className="mt-3">
                    <Form.Label>Select Local MP3 File</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".mp3,audio/mpeg"
                      onChange={handleSynthFileChange}
                    />
                    {synthUploadedFile && (
                      <Form.Text className="text-muted">{synthUploadedFile.name}</Form.Text>
                    )}
                  </Form.Group>
                )}
                <Button variant="primary" onClick={handleSynthesize} className="mt-4" disabled={synthLoading}>
                  {synthLoading ? 'Synthesizing...' : 'Synthesize and Play'}
                </Button>
              </Form>
              {audioSynthOut}
              {error && <div>{error}</div>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { VoiceCloneCreatorOptions };
